<template>
  <div>
    <ConfigurationHeaderContainer title="Stages for Project X"></ConfigurationHeaderContainer>
    <div class="form-entry-container">
      <div class="form-entry-label">Define Stages (The last stage in the sequence will be considered the final citation destination).</div>
      <div class="form-entry-input">
        <div class="stages">
          <div class="stages-item">
            <div class="forms">
              <div>
                <kendo-datasource
                  :ref="'stageDataSource'"
                  :data="stageDataSource.data">
                </kendo-datasource>
                <kendo-listbox
                  :id="'stageListBox'"
                  :data-source-ref="'stageDataSource'"
                  :data-value-field="'StageIdentity'"
                  :data-text-field="'StageName'"
                  :draggable="true"
                  :drop-sources="['selected']"
                  :toolbar-tools="['moveUp', 'moveDown']"
                  :data-bound="stageDataBound"
                  style="height:120px; padding-right: 15px;">
                </kendo-listbox>
                <div>
                  <a href="#">Add</a>
                  <a href="#">Edit</a>
                  <a href="#">Delete</a>
                </div>
              </div>
              <div>
                <div>Manage Cards per Stage for the individual review option</div>
                <kendo-grid
                  :id="'manageStageGrid'"
                  :ref="'manageStageGrid'"
                  :data-source-ref="'stageDataSource'"
                  :columns="columns"
                  :data-bound="stageCardDataBound"
                  style="width: calc(100% - 320px);">
                </kendo-grid>
              </div>
              <div>Note: The same configured options above will be applied on the Batch review, Study mapping Data extraction, Stage Discrepancies and Stage Validation options</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <FormEntryContainer title="Alias for Exclusion Stage" type="text" value="Exclusion"></FormEntryContainer> -->
    <div class="form-entry-container">
      <div class="form-entry-label">Always "Push to" Next in Sequence with the exception of the exclusion stage</div>
      <div class="form-entry-input">
        <input type="radio" name="stage-option1" checked>
        <label for="single">Yes</label>
        <input type="radio" name="stage-option1" disabled="disabled">
        <label for="single" disabled="disabled">No</label>
      </div>
    </div>
    <!-- <div class="form-entry-container">
      <div class="form-entry-label">Default "Push to" Stage  (Will be removed)</div>
      <div class="form-entry-input">
        <input type="radio" name="stage-option2">
        <label for="single">Next in Sequence</label>
        <input type="radio" name="stage-option2">
        <label for="single">Blank</label>
      </div>
    </div> -->
    <div class="form-entry-container">
      <div class="form-entry-label">Default Import Stage for new citations</div>
      <div class="form-entry-input">
        <kendo-dropdownlist
          :id="'stageListBox'"
          :data-source-ref="'stageDataSource'"
          :data-value-field="'StageIdentity'"
          :data-text-field="'StageName'">
        </kendo-dropdownlist>
      </div>
    </div>
    <div class="form-entry-container">
      <div class="form-entry-label">Default Import Stage for Added Materials</div>
      <div class="form-entry-input">
        <kendo-dropdownlist
          :id="'stageListBox2'"
          :data-source-ref="'stageDataSource'"
          :data-value-field="'StageIdentity'"
          :data-text-field="'StageName'">
        </kendo-dropdownlist>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import CardDataSource from '@/assets/data/Card.json'
import StageDataSource from '@/assets/data/Stage.json'
import StageCardDataSource from '@/assets/data/StageCard.json'

export default {
  name: 'stages',
  components: {
    ConfigurationHeaderContainer
  },
  data () {
    return {
      selectable: false,
      sortable: false,
      stageDataSource: StageDataSource,
      columns: this.generateColumns()
    }
  },
  methods: {
    stageDataBound: function () {
      this.$store.state.isLoading = false
    },
    stageCardDataBound: function () {
      document.getElementById('scAllowExclude_1').setAttribute('checked', '')
      document.getElementById('scAllowExclude_2').setAttribute('checked', '')
      // document.getElementById('scLockReview_2').setAttribute('checked', '')
      StageCardDataSource.data.forEach(function (value) {
        var elementId = 'sc_' + value.StageIdentity + '_' + value.CardIdentity
        if (document.getElementById(elementId) !== null) {
          document.getElementById(elementId).setAttribute('checked', '')
        }
      })
    },
    generateColumns: function () {
      var columnsObject = []
      columnsObject.push({ field: 'StageName', title: 'Stages', width: '140px', headerAttributes: { class: 'grid-header-light' } })
      // columnsObject.push({ title: 'Allow Exclusion', template: '<input type="checkbox" id="scAllowExclude_#: StageIdentity #">', width: '1fr' })
      columnsObject.push({ title: 'Allow Study Mapping', template: '<input type="checkbox" id="scAllowExclude_#: StageIdentity #">', width: '1fr', headerAttributes: { class: 'grid-header-light' } })
      // columnsObject.push({ title: 'Lock', template: '<input type="checkbox" id="scLockReview_#: StageIdentity #">', width: '1fr' })
      CardDataSource.data.forEach(function (value) {
        columnsObject.push({ field: 'CardIdentity_' + value.CardIdentity, title: value.CardName, template: '<input type="checkbox" id="sc_#: StageIdentity #_' + value.CardIdentity + '">', width: '1fr', headerAttributes: { class: 'grid-header-light' } })
      })
      return columnsObject
    }
  },
  mounted: function () {
    var stageListBox3Widget = this.$refs.stageListBox3.kendoWidget()
    stageListBox3Widget.select(2)
  }
}
</script>
<style>
input:disabled+label {
  color: #ccc;
}</style>
